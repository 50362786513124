<template>
  <figure :style="{ '--aspectratio': calcRatio(), 'width': adjustedWidth() }">
    <object
      type="image/svg+xml"
      :data="brandsrc()"
      oncontextmenu="return false"
    />
    <figcaption v-html="brand.caption" />
  </figure>
</template>

<script>
export default {
  name: "SvgObject",
  data() {
    return {};
  },
  props: {
    brand: {
      type: Object,
      default: () => {}
    },
    ratio: {
      type: Number,
      default: 0
    }
  },
  methods: {
    brandsrc() {
      let u = this.brand.resplogo
      let t = ''
      if (!this.$store.state.color) t += 'bw'
      if (this.$store.state.darkmode) t += 'inverted'
      return (t === '') ? u : u + '#' + t
    },
    adjustedWidth() {
      if (this.$store.state.ratios.length > 1) {
        let r = eval(this.$store.state.ratios[this.ratio])
        if(r < 3) {
          let f = (window.innerWidth < 640) ? 50 : 25;
          return (r*f < 100) ? (r*f) + '%' : '100%'
        } else {
          return '100%'
        }
      } else {
        return false
      }
    },
    calcRatio() {
      return eval(this.$store.state.ratios[this.ratio])
    }
  },
};
</script>

<style scoped lang="scss">
figure {
  margin: 0 auto;
  border: 1px solid rgba(192,192,192,0.5);
  border-radius: $buttonRadius;
}
figcaption {
  margin: 2em 0;
  display: none;
}
object, img {
  display: block;
  width: 100%;
  pointer-events: none;
  touch-action: none;
/*   border: 1px solid red; */
}
</style>
