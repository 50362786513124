<template>
  <div 
    id="screen" 
    class="screen"
  >
    <div 
      class="modal" 
      :style="sectionStyle()"
    >
      <nav>
        <router-link 
          v-for="(rat, index) in $store.state.ratios"
          :key="index" 
          :to="{path: $route.path, query: {r: index, i: $route.query.i}}"
          :style="{ 'width': adjustedWidth(index)}"
          :class="[{'current': (index == 0 && !$route.query.r) || index == $route.query.r}, ratLabel(index)]"
        />
      </nav>
      <SvgObject
        v-if="$store.state.brand" 
        :ratio="$route.query.r" 
        :key="$store.state.SvgObjectKey" 
        :brand="$store.state.brand"
      />
      <article>
        <router-link
          v-if="prevBrand()" 
          :to="{path: '/logos/' + prevBrand(), query: { i: ($route.query.i>0) ? $route.query.i - 1: 0}}" 
          class="dio-button dio-previous" 
          title="Previous brand"
        >
          Previous
        </router-link>
        <router-link 
          v-if="nextBrand()" 
          :to="{path: '/logos/' + nextBrand(), query: { i: ($route.query.i<$store.state.brands.length) ? parseInt($route.query.i) + 1: 0}}" 
          class="dio-button dio-next" 
          title="Next brand"
        >
          Next
        </router-link>
        <h2 v-text="$store.state.brand.title" />
        <div v-html="$store.state.brand.description" />
      </article>
    </div>
  </div>
</template>

<script>
import SvgObject from "@/components/SvgObject.vue";

export default {
  name: "Item",
  data() {
    return {
      ratio: 0,
      error: null,
    }
  },
  components: {
    SvgObject
  },
  methods: {
    sectionStyle: function() {
      if (this.$store.state.darkmode) {
        return this.$store.state.brand.invertedStyles
      } else {
        return this.$store.state.brand.styles
      }
    },
    switchRatio: function(event) {
      this.ratio = parseInt(event.target.innerText) 
    },
    adjustedWidth: function(i) {
      let r = eval(this.$store.state.ratios[i])
      return (r*1.5) + 'em'
    },
    ratLabel: function(x) {
      if (this.$route.query.r !== undefined) {
        if (x < this.$route.query.r) {
          return "decrease"
        } else if (x > this.$route.query.r) {
          return "increase"
        } else {
          return ""
        }
      } else if (x > 0) {
        return "increase"
      } else {
        return ""
      }
    },
    prevBrand() {
      let i = parseInt(this.$route.query.i)
      if(i && i > 0) {
        return this.$store.state.brands[i-1].slug 
      } else {
        return false
      }
    },
    nextBrand() {
    /* IN APP ZETTEN */
      let i = parseInt(this.$route.query.i)
      if(i<this.$store.state.brands.length) {
        return this.$store.state.brands[i+1].slug 
      } else {
        return false
      }
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.$store.commit("getSingleBrand", this.$route.params.slug)
    }
    let screen = document.getElementById("screen")
    screen.removeAttribute("style")
    screen.classList.add("dio-show")
    console.log(this.$route.params)
  },
  beforeRouteLeave(to, from, next) {
    let screen = document.getElementById("screen")
    screen.classList.remove("dio-show")
    this.$store.commit("getSingleBrand")
    next();
  }
};
</script>

<style lang="scss" scoped>
.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1vh solid;
  background-color: white;
  overflow: auto;
  .darkmode & {
    color: $invertedIextColor;
    background-color: $invertedHeaderBackgroundColor;
  }
  transition: background 1s ease, filter 1s ease-in-out;
}
.modal {
  max-width: $medium;
  margin: 0 auto;
  padding: 1rem 2rem;
  pointer-events: initial;
}
.dio-close {
  top: 1rem;
  right: 1rem;
}
.dio-flipped {
  display: inline-block;
  transform: rotateY(180deg);
}
h1 {
  padding: 0.25em 0;
  text-align: center;
}
nav {
  margin: 0 auto;
  text-align: left;
  > * {
    $button-size: 3;
    display: block;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    margin: 0 $cardPadding $cardPadding 0;
    border: 1px solid rgba(192,192,192,0.75);
    text-align: center;
    border-radius: $buttonRadius;
    &.increase, &.decrease {
      &::after, &::before {
        position: absolute;
        top: 50%;
        width: 1.5em;
        height: 1.5em;
        transform: translateY(-50%);
        content: '';
        background: {
          image: url("/resources/sprites.svg");
          size: #{$button-size*4}em #{$button-size/2}em;
          repeat: no-repeat;
          position: -#{$button-size*3}em 0;
        }
      }
      &::before {
        right: 50%;
      }
      &::after {
        left: 50%;
      }
    }
    &.decrease::after {
      transform: rotate(180deg) translateY(50%);
    }
    &.increase::before {
      transform: rotate(180deg) translateY(50%);
    }
  }
  .current {
    background-color: rgba(192,192,192,0.5);
  }
}
article {
  position: relative;
  margin: $cardPadding*2 auto;
}
@media only screen and (min-width: 512px) {
  nav > * {
    display: inline-block;
  }
}
@media only screen and (min-width: $small) {
  nav {
    max-width: 75vw;
  }
}
@media only screen and (min-width: $medium) {
  nav {
    max-width: 50vw;
  }
}
@media only screen and (min-width: $large) {
  nav {
    max-width: $small;
  }
}
</style>